import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function MSStream(props: Props) {
  const { matches } = props.attrs;
  const videoId = matches[1];
  return (
    <Frame
      {...props}
      src={`https://web.microsoftstream.com/embed/video/${videoId}?autoplay=false&showinfo=true" allowfullscreen style="border:none;"`}
      title={`Microsoft Stream (${videoId})`}
    />
  );
}

MSStream.ENABLED = [
  /(?:https?:\/\/)?(?:web\.)?microsoftstream(?:\.com)?\/?.*(?:embed)?\/?.*(?:video)?\/?.*([a-zA-Z0-9_-]{36})$/i,
];

export default MSStream;
